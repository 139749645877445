// COLORS
$primary: #0c00ff;
$white: #B0B0B0;
$secondary-purple: #c75e9a;
$primary-black: #000;
$primary-blue: #2338ff;
$primary-white: #ffffff;
$primary-bg: white;
$drop-color: rgba(white, .98);
$dark-blue: #1D314D;
$light-cyan: #E6F4F8;
// BREAKPOINTS

$_1920: 1920px;
$_1600: 1600px;
$_1440: 1440px;
$_1200: 1200px;
$_800: 800px;


// VARIABLES

:root {
  --primary-color: #{$white};
}
