.our-services {
  position: relative;
  box-sizing: border-box;
  min-height: 1030px;
  padding-top: 94px;
  padding-bottom: 143px;
  background: linear-gradient(94.68deg, #1F4FAA -4.37%, #073385 41.82%, #061E4B 111.24%);

  .net {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .net-mobile {
    display: none;
  }

  .our-services__wrapper {

    .our-services__wrapper-title {
      font-family: Rubik;
      font-size: 44px;
      font-weight: 400;
      line-height: 52px;
      letter-spacing: 0em;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 72px;
      z-index: 3;

      span {
        font-weight: 600;
      }
    }

    .our-services__wrapper-box-top {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 39px;
      z-index: 3;
      margin-bottom: 90px;

    }

    .our-services__wrapper-box-down {
      max-width: 1093px;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 39px;
      z-index: 3;
    }
  }

  .white-box {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 289px;
    padding: 30px 40px 36px 35px;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    z-index: 3;

    img + .title {
      margin-top: 18px;
    }

    .title + p {
      margin-top: 23px;
    }

    .svg {
      width: 45px;
      height: 45px;
    }

    .title {
      font-family: Rubik;
      font-size: 23px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;

      .svg-mobile {
        display: none;
      }

    }

    p {
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 404px;
    }

    .gradient {
      position: absolute;
      right: 35px;
      top: 50%;
      transform: translateY(-50%);
      width: 230px;
      height: 230px;
    }

  }

  @media all and (max-width: $_1600) {
    min-height: 772px;
    padding-top: 70px;
    padding-bottom: 84px;
    .net {
      width: 690px;
      height: 690px
    }
    .our-services__wrapper {

      .our-services__wrapper-title {
        font-size: 33px;
        line-height: 39px;
        margin-bottom: 54px;

      }

      .our-services__wrapper-box-top {
        grid-column-gap: 29px;
        margin-bottom: 44px;

      }

      .our-services__wrapper-box-down {
        max-width: 820px;
        grid-column-gap: 30px;

      }
    }
    .white-box {
      min-height: 240px;
      padding: 22px 10px 32px 28px;

      img + .title {
        margin-top: 13px;
      }

      .title + p {
        margin-top: 17px;
      }

      .svg {
        width: 33px;
        height: 33px;
      }

      .title {
        font-size: 17px;
        line-height: 20px;


      }

      p {
        font-size: 14px;
        line-height: 19px;
        max-width: 352px;
      }

      .gradient {
        right: 24px;
        width: 172px;
        height: 172px;
      }

    }

  }
  @media all and (max-width: $_800) {
    min-height: 1352px;
    padding-top: 47px;
    padding-bottom: 54px;
    .net {
      display: none;
    }
    .net-mobile {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: block;
    }
    .our-services__wrapper {

      .our-services__wrapper-title {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 33px;

      }

      .our-services__wrapper-box-top {
        grid-row-gap: 15px;
        grid-template-columns: unset;
        grid-template-rows: 1fr;
        margin-bottom: 15px;

      }

      .our-services__wrapper-box-down {
        grid-template-columns: unset;
        max-width: 100%;
        grid-template-rows: 1fr;
        grid-row-gap: 15px;

      }
    }
    .white-box {
      min-height: 224px;
      padding: 19px 10px 15px 20px;

      img + .title {
        margin-top: 13px;
      }

      .title + p {
        margin-top: 18px;
      }

      .svg {
        display: none;
      }

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 18px;
        gap: 10px;

        .svg-mobile {
          display: block;
          width: 30px;
          height: 30px;
        }

      }

      p {
        font-size: 14px;
        line-height: 21px;
        max-width: 100%;
      }

      .gradient {
        top: unset;
        transform: unset;
        bottom: 0;
        right: 0;
        width: 162px;
        height: 162px;
      }

    }
  }

}