body,
html {
  margin: 0 auto;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  color: #000;
  background-color: #B0B0B0;
}

html {
  scroll-behavior: auto;
}

body {
  //overflow-x: hidden; //this disables ability to use position: sticky
  transition: background-color 1s ease;
  -webkit-transition: background-color 1s ease;
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
}

* {
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
}

a {
  display: block;
  height: fit-content;
  width: fit-content;
}

h1,
h2,
h3,
h4,
p, span {
  margin: 0;
}