.navbar {
  width: 100%;
  min-height: 100px;
  background-color: transparent;
  transition: all 0.2s;
  z-index: 999;

  &-color {
    background-color: rgb(255, 255, 255);
  }

  .navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .logo-nav {
    width: 225px;
    height: 36px;
  }

  .navbar-box-link {
    box-sizing: border-box;
    max-width: 521px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      transition: all 0.2s;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: from-font;
      }

    }
  }

  .nav-burger {
    display: none;
  }

  @media all and (max-width: $_1600) {
    min-height: 75px;
    .logo-nav {
      width: 173px;
      height: 27px;
    }

    .navbar-box-link {
      max-width: 492px;

      a {
        font-size: 14px;
        line-height: 16px;
      }
    }

  }
  @media all and (max-width: $_1200) {
    min-height: 61px;
    .logo-nav {
      width: 180px;
      height: 28px;
    }
    .navbar-box-link{
      display: none;
    }
    .nav-burger {
      display: block;
    .burger{
      width: 18px;
      height: 12px;
      &:hover{
        cursor: pointer;
      }
    }
    }
  }

}