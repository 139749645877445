.get-in-touch {
  max-width: 1920px;
  width: 100%;
  min-height: 728px;
  background-color: #FFFFFF;
  contain: paint;
  padding-bottom: 114px;

  .font-input-size {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0,0,0,0.5);

  }

  .get-in-touch__container {
    display: flex;
    width: 100%;
    min-height: 614px;
    border-radius: 10px;

    .get-in-touch__container-box-left {
      position: relative;
      max-width: 625px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;

      .text-image {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 53px 54px;

        h4 {
          font-family: Rubik;
          font-size: 53px;
          font-weight: 400;
          line-height: 63px;
          letter-spacing: 0em;
          text-align: left;
          color: #FFFFFF;
          margin-bottom: 15px;


          span {
            font-weight: 600;
            color: #FF7A00;
          }

        }

        p {
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #FFFFFF;
          max-width: 432px;
        }
      }

      .img-left {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }


    }

    .get-in-touch__container-box-right {
      box-sizing: border-box;
      max-width: 1035px;
      width: 100%;
      height: 616px;
      background-color: rgba(31, 79, 170, 0.1);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 37px 72px 0px 65px;

      .form-setting {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        label {
          font-family: Rubik;
          font-size: 16px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: left;
          color: #000000;
          opacity: 0.5;
          margin-bottom: 7px;

        }

        .text {
          background: #FFFFFF;
          border-radius: 10px;
          border: none;
          padding-left: 20px;


          &:focus-visible {
            outline: none;
          }

          &::placeholder {
            color: #D5D5D5;

          }
        }

        .text-margin {
          margin-bottom: 26px;
        }

        .text-topic {
          height: 57px;
        }

        .text-message {
          height: 200px;
          padding-top: 18px;
          writing-mode: unset;
          display: flex !important;
          text-align: start !important;


        }

        .button-black {
          position: absolute;
          right: 0;
          bottom: 124px;
          max-width: 148px;
          gap: 14px;
          border: none;
        }

        .add {
          position: absolute;
          right: 0;
          bottom: 88px;
          font-family: Rubik;
          font-size: 12px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          color: #000000;
          opacity: 0.5;
        }

        .email {
          box-sizing: border-box;
          position: absolute;
          right: 0;
          bottom: 124px;
          width: 514px;
          height: 52px;
          border-radius: 100px;
          padding-left: 43px;
          border: 0;
          font-family: Rubik;
          font-size: 16px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: left;
          color: #000000;
          opacity: 0.5;
          border: 1px solid #000000;
        }

        .button-mobile {
          display: none;
        }
      }

    }
  }

  @media all and (max-width: $_1600) {
    min-height: 545px;
    padding-bottom: 85px;

    .get-in-touch__container {
      min-height: 460px;


      .get-in-touch__container-box-left {
        max-width: 468px;

        .text-image {
          padding: 20px 40px;

          h4 {
            margin-bottom: 10px;

            span {

            }

          }

          p {
            max-width: 405px;
          }
        }
      }

      .get-in-touch__container-box-right {

        max-width: 802px;
        height: 462px;
        padding: 27px 54px 0px 49px;

        .form-setting {

          label {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 7px;

          }

          .text {
            padding-left: 15px;
          }

          .text-margin {
            margin-bottom: 19px;
          }

          .text-topic {
            height: 42px;
          }

          .text-message {
            height: 150px;
            padding-top: 13px;
          }

          .button-black {
            bottom: 93px;
            max-width: 111px;
            gap: 10px;
            height: 39px;
          }

          .add {
            bottom: 61px;
          }

          .email {
            bottom: 93px;
            width: 385px;
            height: 39px;
            border-radius: 100px;
            padding-left: 32px;
            font-size: 14px;
            line-height: 21px;
          }
        }

      }
    }
  }
  @media all and (max-width: $_800) {
    min-height: 545px;
    padding-bottom: 85px;

    .get-in-touch__container {
      min-height: 460px;
      flex-direction: column;


      .get-in-touch__container-box-left {
        max-width: 100%;
        height: 319px;
        border-bottom-left-radius: 0;

        .img-left {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }

        .text-image {
          padding: 18px 16px 21px 25px;

          h4 {
            font-size: 33px;
            line-height: 39px;
            margin-bottom: 15px;

            span {
              font-weight: 600;
              color: #FF7A00;
            }

          }

          p {
            font-family: Rubik;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFFFFF;
            max-width: 100%;
          }
        }


      }

      .get-in-touch__container-box-right {

        max-width: 100%;
        height: 443px;
        padding: 21px 23px 0px 25px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;

        .form-setting {

          label {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 6px;

          }

          .text {
            padding-left: 20px;
          }

          .text-margin {
            margin-bottom: 19px;
          }

          .text-topic {
            height: 48px;
          }

          .text-message {
            height: 158px;
            padding-top: 15px;
          }

          .button-black {
            bottom: 55px;
            max-width: 52px;
            height: 52px;
          }

          .add {
            bottom: 18px;
            font-size: 10px;
            line-height: 15px;


          }

          .email {
            box-sizing: border-box;
            bottom: 55px;
            width: 276px;
            height: 52px;
            padding-left: 20px;

          }

          .button-desktop {
            display: none;
          }

          .button-mobile {
            display: flex;
          }
        }

      }
    }
  }
}