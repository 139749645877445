html,
body {
  background-color: #B0B0B0;
  box-sizing: border-box;

}

.screen-size {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  z-index: 999;


}

.content {
  background-color: green;
  height: 1200px;
}

.wrapper {
  box-sizing: border-box;
  max-width: 1720px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  @media all and (max-width: $_1600) {
    max-width: 1305px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  @media all and (max-width: $_1200) {
    max-width: 100%;
    padding: 0 25px;
  }
}
section{
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;

}