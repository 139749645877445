.cookies {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding: 0;


  .cookies__container {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    min-height: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 25px 73px 30px 111px;


  }

  .cookies__container-text {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #252525;
    max-width: 1100px;

    .cookies__container-text-title{
      font-family: Rubik;
      font-size: 26px;
      font-weight: 500;
      line-height: 39px;
      letter-spacing: 0em;
      text-align: left;
      color: #2A2525;
      margin-bottom: 8px;

    }
    a {
      font-weight: 600;
      display: contents;
      color: #1E4182;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }

  .accept-button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 204px;
    width: 100%;
    height: 52px;
    padding: 0;
    background-color: #1E4182;
    color: #FFFFFF;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 100px;
    border: none;
    transition: all 0.2s;
    &:hover{
      cursor:pointer;
      opacity: 0.8;
    }

  }

  .cookies__container-exit {
    position: absolute;
    top: 34px;
    right: 72px;
    z-index: 3;

    &:hover {
      cursor: pointer;
    }
  }

  @media all and (max-width: $_1600) {


    .cookies__container {
      padding: 18px 55px 30px 83px;
      min-height: 127px;
    }

    .cookies__container-text {
      font-size: 12px;
      line-height: 17px;
      max-width: 823px;
      margin-right: 10px;
      .cookies__container-text-title{
        font-family: Rubik;
        font-size: 20px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

      }


    }
    .cookies__container-exit {
      position: absolute;
      top: 26px;
      right: 54px;
      z-index: 3;
      width: 14px;
      height: 14px;

      &:hover {
        cursor: pointer;
      }
    }
    .accept-button {
      max-width: 153px;
      height: 39px;
      font-size: 14px;
      line-height: 18px;


    }

  }
  @media all and (max-width: $_1200) {
    .cookies__container {
      flex-direction: column;
      padding: 20px 28px 26px 28px;
      min-height: auto;
      align-items:self-end;
    }

    .cookies__container-text {
      font-size: 10px;
      line-height: 14px;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 10px;

      .cookies__container-text-title {
        font-size: 13px;
        line-height: 39px;
        margin-bottom: 0;
      }


    }
    .cookies__container-exit {
      top: 19px;
      right: 28px;
      width: 13px;
      height: 13px;

      &:hover {
        cursor: pointer;
      }
    }
    .accept-button {
      max-width: 204px;
      height: 52px;
      font-size: 16px;
      line-height: 20px;


    }
  }
}

.d-none {
  display: none;
}