.button-black {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  border-radius: 100px;
  max-width: 204px;
  width: 100%;
  height: 52px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media all and (max-width: $_1600){
    font-size: 14px;
    line-height: 18px;
    max-width: 153px;
    height: 39px;
  }
  @media all and (max-width: $_800){
    font-size: 16px;
    line-height: 20px;
    max-width: 204px;
    height: 52px;
  }

}