.why-innovative {
  position: relative;
  box-sizing: border-box;
  min-height: 564px;
  padding: 122px 0;
  background-color: #FFFFFF;

  .gradient {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .why-innovative_wrapper-container {
    max-width: 1359px;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    .why-innovative_wrapper-container-title {
      max-width: 337px;
      width: 100%;
      z-index: 3;

      .title {
        font-family: Rubik;
        font-size: 44px;
        font-weight: 600;
        line-height: 52px;
        letter-spacing: 0em;
        text-align: left;

        span {
          color: #FF7A00;
        }
      }


    }

    .why-innovative_wrapper-container-description {
      max-width: 863px;
      font-family: Rubik;
      font-size: 23px;
      font-weight: 400;
      line-height: 35px;
      letter-spacing: 0em;
      text-align: left;

      span {
        font-weight: 600;
      }

      .color {
        color: #FF7A00;
      }

    }

  }

  @media all and (max-width: $_1600) {
    min-height: 423px;
    padding: 91px 0;
    .gradient {
      width: 440px;
    }

    .why-innovative_wrapper-container {
      max-width: 1018px;

      .why-innovative_wrapper-container-title {
        max-width: 252px;
        .title {
          font-size: 33px;
          line-height: 39px;
        }

      }

      .why-innovative_wrapper-container-description {
        max-width: 647px;
        font-size: 17px;
        line-height: 25px;


      }

    }

  }

  @media all and (max-width: $_800) {
    min-height: 454px;
    padding: 48px 0;
    .gradient {
      width: 374px;
    }

    .why-innovative_wrapper-container {
      max-width: 100%;
      flex-direction: column;
      justify-content: unset;
      gap: 38px;

      .why-innovative_wrapper-container-title {
        display: flex;
        flex-direction: column;
        gap: 13px;
        max-width: 100%;
        .title {
          font-size: 28px;
          line-height: 33px;
        }
        .line {
          max-width: 234px;
          width: 100%;
        }

      }

      .why-innovative_wrapper-container-description {
        max-width: 100%;
        font-size: 14px;
        line-height: 21px;


      }

    }
  }
}