.footer {
  box-sizing: border-box;
  max-width: 1920px;
  width: 100%;
  contain: paint;
  min-height: 658px;
  background: linear-gradient(117.23deg, #1F4FAA 43.52%, #062258 115.05%);
  padding: 85px 0;

  .footer-wrapper {
    display: flex;
    flex-direction: column;
  }

  .line {
    width: 234px;
    margin-bottom: 15px;
  }

  .footer-row {
    max-width: 1008px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 157px;

    .logo {
      width: fit-content;
      font-family: Rubik;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;

      p {
        max-width: 421px;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        opacity: 0.5;
        margin-top: 13px;
      }

      .linkedin {
        width: 16px;
        height: 16px;
        margin-top: 13px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .footer-row__box-category {
    display: flex;
    gap: 60px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #F4F7FF;


    a:hover {
      text-decoration: underline;
      text-underline-position: from-font;
      cursor: pointer;

    }


  }

  .footer-contact {
    display: flex;
    flex-direction: column;

    a {
      font-family: Rubik;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

      background-image: linear-gradient(90deg, #FF7A00 0%, #FFFFFF 96.31%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      margin-bottom: 18px;

    }


    .contact {
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;
      opacity: 0.5;
      margin-bottom: 34px;

    }

  }

  .footer-line {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    opacity: 0.2;
    margin-bottom: 25px;
  }

  .footer-row__political {
    display: flex;
    justify-content: space-between;

    .rights {
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;
      opacity: 0.5;

      span {
        font-weight: 700;
      }
    }

    .box-politic {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;

      a {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: right;
        color: #FFFFFF;
        opacity: 0.5;
        transition: all 0.2s;

        &:hover {
          text-decoration: underline;
          text-underline-position: from-font;
          cursor: pointer;

        }
      }
    }
  }

  @media all and (max-width: $_1600) {
    min-height: 493px;
    padding: 56px 0;


    .line {
      width: 175px;
      margin-bottom: 11px;
    }

    .footer-row {
      max-width: 771px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 93px;

      .logo {
        font-size: 17px;
        line-height: 20px;

        p {
          max-width: 316px;
          font-size: 14px;
          line-height: 18px;
          margin-top: 13px;
        }

        .linkedin {
          width: 12px;
          height: 12px;
          margin-top: 10px;

        }
      }
    }

    .footer-row__box-category {
      gap: 32px;
      font-size: 14px;
      line-height: 16px;


    }

    .footer-contact {


      a {
        font-size: 17px;
        line-height: 17px;
        margin-bottom: 14px;

      }


      .contact {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 21px;

      }

    }

    .footer-line {
      margin-bottom: 17px;
    }

    .footer-row__political {
      .rights {
        font-size: 12px;
        line-height: 12px;
      }

      .box-politic {

        gap: 30px;

        a {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
  @media all and (max-width: $_800) {

    min-height: 643px;
    padding: 54px 0;


    .line {
      width: 324px;
      margin-bottom: 15px;
    }

    .footer-row {
      max-width: 100%;
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
      margin-bottom: 60px;

      .logo {
        font-size: 20px;
        line-height: 23px;

        p {
          max-width: 324px;
          font-size: 14px;
          line-height: 16px;
          margin-top: 15px;
        }

        .linkedin {
          width: 16px;
          height: 16px;
          margin-top: 20px;
          margin-bottom: 44px;

        }
      }
    }

    .footer-row__box-category {
      flex-direction: column;
      gap: 15px;
      font-size: 14px;
      line-height: 16px;


    }

    .footer-contact {


      a {
        font-size: 15px;
        line-height: 15px;
        margin-bottom: 11px;

      }


      .contact {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 28px;

      }

    }

    .footer-line {
      margin-bottom: 17px;
    }

    .footer-row__political {
      .rights {
        max-width:159px ;
        font-size: 12px;
        line-height: 12px;
      }

      .box-politic {
        flex-wrap: unset;
        flex-direction: column;
        gap: 4px;

        a {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }

  }
}