.choose-innovative {
  box-sizing: border-box;
  position: relative;
  max-width: 1920px;
  width: 100%;
  padding-top: 141px;
  padding-bottom: 112px;
  min-height: 1743px;
  background-color: #FFFFFF;
  contain: paint;

  .gradient-general {
    position: absolute;
    top: 413px;
    left: 0;
    z-index: 1;
  }

  .choose-innovative__wrapper {
    margin-left: 130px;

  }

  .choose-innovative__container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .choose-innovative__container-box-left {
    max-width: 660px;
    width: 100%;
    min-height: 1492px;

    .choose-innovative__container-box-left-general-title {
      position: sticky;
      top: 100px;
      display: flex;
      flex-direction: column;
      z-index: 5;


      .title {
        max-width: 450px;
        font-family: Rubik;
        font-size: 44px;
        font-weight: 600;
        line-height: 52px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 31px;
        z-index: 5;

        span {
          color: #FF7A00;
        }
      }

      .img-line {
        max-width: 234px;
        margin-bottom: 16px;
      }

      p {
        font-family: Rubik;
        font-size: 23px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        z-index: 5;

      }
    }
  }

  .choose-innovative__container-box-right {
    display: flex;
    flex-direction: column;
    gap: 42px;
    max-width: 979px;
    width: 100%;

  }
  .box-example{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding:40px 350px 63px 58px;
    border-radius: 10px;
    border: 1px solid #CFCFCF;
    min-height: 341px;
    z-index: 2;
    background-color: #FFFFFF;
    .number{
      color: #FF7A00;
      font-family: Rubik;
      font-size: 44px;
      font-weight: 600;
      line-height: 52px;
      letter-spacing: 0em;
      text-align: left;
      z-index: 2;
    }
    .title{
      font-family: Rubik;
      font-size: 23px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      z-index: 2;
    }
    .description{
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      z-index: 2;


    }
    &--color{
      border: none;
      background-color: #FF7A00;
      color:#FFFFFF;
      .number{
        color:#FFFFFF;

      }
    }
    &:first-of-type{
      .title{
        max-width:401px;
      }
      p{
        max-width: 491px;
      }
    }
    &:nth-of-type(2){
      p{
        max-width: 546px;
      }
    }
    &:nth-of-type(3){
      p{
        max-width: 491px;
      }
    }
    &:nth-of-type(4){
      p{
        max-width: 486px;
      }
    }
  }
  .choose-innovative__container-swiper{
    display: none;
  }
  @media all and (max-width: $_1600){
    padding-top: 106px;
    padding-bottom: 84px;
    min-height: 1307px;

    .gradient-general {
      top: 310px;
      width: 543px;
      height: 780px;
    }

    .choose-innovative__wrapper {
      margin-left: 97px;

    }

    .choose-innovative__container-box-left {
      max-width: 495px;
      width: 100%;
      min-height: 1113px;

      .choose-innovative__container-box-left-general-title {
        top: 75px;

        .title {
          max-width: 340px;
          font-size: 33px;
          line-height: 39px;
          margin-bottom: 23px;

        }

        .img-line {
          max-width: 175px;
          margin-bottom: 12px;
        }

        p {
          font-size: 17px;
          line-height: 23px;
        }
      }
    }

    .choose-innovative__container-box-right {
      gap: 31px;
      max-width: 734px;


    }
    .box-example{
      gap: 16px;
      padding:30px 150px 50px 43px;
      min-height: 255px;

      .number{
        font-size: 33px;
        line-height: 39px;
      }
      .title{
        font-size: 17px;
        line-height: 20px;
      }
      .description{
        font-size: 14px;
        line-height: 19px;
      }
      &:first-of-type{
        .title{
          max-width:300px;
        }
        p{
          max-width: 490px;
        }
      }
      &:nth-of-type(2){
        p{
          max-width: 498px;
        }
      }
      &:nth-of-type(3){
        p{
          max-width: 448px;
        }
      }
      &:nth-of-type(4){
        p{
          max-width: 443px;
        }
      }
    }
  }
  @media all and (max-width: $_800){
    padding-top: 42px;
    padding-bottom: 30px;
    min-height: 741px;

    .gradient-general {
      position: absolute;
      top: 207px;
      width:350px;
      height:504px;
    }

    .choose-innovative__wrapper {
      margin-left: 24px;

    }

    .choose-innovative__container {
      display: flex;
      flex-direction: column;
      justify-content: unset;
      gap: 35px;
    }

    .choose-innovative__container-box-left {
      max-width: 100%;
      min-height: auto;

      .choose-innovative__container-box-left-general-title {
        position: relative;
        top: unset;

        .title {
          max-width: 246px;
          font-size: 28px;
          line-height: 33px;
          margin-bottom: 12px;

        }

        .img-line {
          max-width: 234px;
          margin-bottom: 13px;
        }

        p {
          font-size: 14px;
          line-height: 19px;
          padding-right: 25px;
        }
      }
    }

    .choose-innovative__container-box-right {
      display: none;
    }
    .choose-innovative__container-swiper{
      display: block;
      .swiper-slide{
        max-width: 292px;
        min-height: 430px;
        padding:37px 26px  117px 24px;
        gap: unset;
        .number{
          margin-bottom: 12px;
        }
        .title{
          margin-bottom: 25px;
        }
      }
      .swiper-pagination{
        bottom:43px;
        .swiper-pagination-bullet{
          width: 8px;
          height: 8px;
          background-color: white;
          border: 1px solid #FF7A00;
          &-active{
            background-color: #FF7A00;

          }
        }
      }
    }
  }
}