.header {
  position: relative;
  width: 100%;
  min-height: 1080px;


  .background-header {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
  .background-header__mobile{
    display: none;
  }

  .img-right {
    position: absolute;
    bottom: -57px;
    right: 0;
    z-index: 3;
  }

  .img-right-tab {
    display: none;
    position: absolute;
    bottom: -50px;
    right: 0;
    z-index: 3;
  }

  .img-right-mobile {
    display: none;
    position: absolute;
    bottom: -30px;
    right: 0;
    z-index: 3;
  }

  .header-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 1080px;
    z-index: 6;
    padding-top: 259px;

    .header-wrapper__title {
      max-width: 866px;
      font-family: Rubik;
      font-size: 71px;
      font-weight: 400;
      line-height: 83px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;
      margin-bottom: 20px;


      span {

        font-family: Rubik;
        font-size: 71px;
        font-weight: 600;
        line-height: 83px;
        letter-spacing: 0em;
        text-align: left;

        &:first-of-type {
          color: #000000;
        }

        &:last-of-type {
          color: #1F4FAA;
        }
      }

    }

    .header-wrapper__description {
      max-width: 459px;
      font-family: Rubik;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 43px;

    }
  }

  @media all and (max-width: $_1600) {
    min-height: 810px;
    .img-right {
      display: none;
    }
    .img-right-tab {
      display: block;
    }
    .header-wrapper {
      min-height: 810px;
      padding-top: 194px;

      .header-wrapper__title {
        max-width: 648px;
        font-size: 53px;
        line-height: 62px;
        margin-bottom: 15px;

        span {
          font-size: 53px;
          line-height: 62px;
        }

      }

      .header-wrapper__description {
        max-width: 364px;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 30px;

      }
    }

  }
  @media all and (max-width: $_1200) {
    min-height: 813px;

    .img-right-tab {
      display: none;
    }
    .img-right-mobile {
      display: block;
    }
    .header-wrapper {
      min-height: 813px;
      padding-top: 116px;


    }
  }
  @media all and (max-width: $_800) {
    .background-header{
      display: none;
    }
    .background-header__mobile{
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
    }
    .header-wrapper {
      .header-wrapper__title {
        max-width: 100%;
        font-size: 35px;
        line-height: 44px;
        margin-bottom: 26px;

        span {
          font-size: 35px;
          line-height: 44px;
        }

      }

      .header-wrapper__description {
        max-width: 100%;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;

      }
    }
  }

}