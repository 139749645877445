.navbar-mobile {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(172.42deg, #1F4FAA 5.87%, #0D2E6D 121.77%);
  z-index: 9999;
  display: none;
  overflow: auto;

  &--active {
    display: block;
  }

  .back-icon {
    &:hover {
      cursor: pointer;
    }

  }

  .navbar-mobile__back-box {
    position: absolute;
    top: 28px;
    right: 25px;
  }

  .navbar-mobile__logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;


    span {
      font-family: Rubik;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;

    }

  }

  .navbar-mobile__links {
    margin-top: 80px;
    padding: 0 25px;

    .navbar-mobile__link {
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      color: #FFFFFF;
      border-bottom: 1px solid rgba(#E0E0E0, 0.5);
      width: 100%;
      padding-bottom: 30px;
      margin-bottom: 30px;

      &:last-child {
        border-bottom: 0
      }
    }

  }
}